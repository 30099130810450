<script>
import CardPublicProfile from '@/components/profile/CardPublicProfile'
export default {
  name: 'BusinessProfile',
  components: { CardPublicProfile },
  data () {
    return {
      workspaceId: null,
      cameFromDiscovery: false
    }
  },
  methods: {
    close () {
      const changeState = {
        actionName: 'attemptSetShouldCachingFilters',
        value: true
      }
      let page = null
      if (this.$store.getters.getDiscoveryPagination) {
        page = this.$store.getters.getDiscoveryPagination.page
      }
      const routesToPush = [{ dir: 'workspace', path: this.selectedWorkspace.type + '.discovery.index', changeState, query: { page } }]
      this.pushToRouteName(routesToPush)
    }
  },
  created () {
    this.workspaceId = parseInt(this.$route.params.id)
    this.cameFromDiscovery = this.$store.getters.getAccessFromDiscovery
    setTimeout(() => {
      this.$store.dispatch('attemptSetAcessContent', false)
    }, 500)
  }
}
</script>
<template>
  <div class="public-access-workspace--container">
    <v-toolbar
      color="#1200D3"
      :height="isMobile ? 195 : 250"
      elevation="0"
    >
      <div v-if="cameFromDiscovery" class="toolbar-content center-medium">
        <v-btn text dark @click="close" class="btn">
          <v-icon>mdi-chevron-left</v-icon>
          {{$t('global:back')}}
        </v-btn>
      </div>
    </v-toolbar>
    <card-public-profile :class="{'mt-n16': !isMobile}"
      :workspaceId="workspaceId"
    />
    <router-view></router-view>
  </div>
</template>
<style scoped>
.public-access-workspace--container {
  padding-bottom: 70px;
}
.toolbar-content {
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .toolbar-content {
    margin-top: 40px;
  }
}
</style>
