<script>
import ModalWorkspaceInfo from '@/components/workspace/ModalWorkspaceInfo'
export default {
  name: 'CardPublicProfile',
  components: { ModalWorkspaceInfo },
  data () {
    return {
      showModalWorkspaceInfo: false,
      selectedTab: 0,
      socialIcons: {
        instagram: 'mdi-instagram',
        twitter: 'mdi-twitter',
        facebook: 'mdi-facebook',
        linkedin: 'mdi-linkedin',
        url: 'mdi-link'
      }
    }
  },
  props: {
    userId: {
      type: Number
    }
  },
  computed: {
    workspaceId () {
      return this.$route.params.id
    },
    businessKeys () {
      return {
        profile: `global:${this.isBusiness ? 'business' : 'personal'}.profile`,
        contentTab: `workspace${this.isBusiness ? '.public' : ''}.contents`,
        listTab: `workspace${this.isBusiness ? '.public' : ''}.lists`,
        editProfile: `global:edit${this.isBusiness ? '.workspace' : '.profile'}`
      }
    },
    isBusiness () {
      return (this.workspaceData && this.workspaceData.type === 'business') || false
    },
    workspaceData () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId] || null
    },
    mainSkills () {
      return this.workspaceData.skills
        .filter(skill => this.workspaceData.type === 'business' || !!skill.isMain)
        .map(skill => {
          const title = skill.title.length > 25 ? skill.title.slice(0, 25) + '...' : skill.title
          return { ...skill, title }
        })
    },
    userWorkspaces () {
      return this.$store.getters.getUserWorkspaces || []
    }
  },
  methods: {
    updateWorkspace () {
      this.$store.dispatch('attemptGetWorkspaceById', this.workspaceId)
    },
    pushToSettings () {
      if (this.isBusiness) {
        this.$store.commit('setSelectedWorkspace', this.workspaceData || this.userWorkspaces.find(workspaceOpt => {
          return workspaceOpt.id === this.workspaceData.id
        }))
        this.$nextTick(() => {
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.management' })
        })
      } else {
        this.$router.push({ name: 'settings.profile.form', params: { id: this.workspaceId } })
      }
    }
  },
  created () {
    this.updateWorkspace()
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler () {
        switch (true) {
          case this.$route.name.includes('content'):
            this.selectedTab = 0
            break
          case this.$route.name.includes('list'):
            this.selectedTab = 1
            break
          case this.$route.name.includes('skill'):
            this.selectedTab = 2
            break
        }
      }
    },
    userId () {
      this.updateWorkspace()
    }
  }
}
</script>

<template>
  <v-card  class="center-medium profile-card" :key="workspaceData && workspaceData.id">
    <section v-if="!workspaceData">
      <v-container class="empty-block">
        <v-img src="/assets/images/user-not-found.svg" width="136" class="empty-img"/>
        <h3 class="empty-title">
          {{$t('card.public.profile.empty:title')}}
        </h3>
        <p class="empty-text">
          {{$t('card.public.profile.empty:txt')}}
        </p>
      </v-container>
    </section>
    <section v-else>
      <div class="profile-card--header">
        <h5 class="profile-card--label h8">
          {{ isBusiness ? $t('global:business.page') : $t('global:personal.profile') }}
        </h5>
        <v-btn v-if="profileBelongsLoggedUser" text class="bolder btn" @click="pushToSettings">
          {{ $t(businessKeys.editProfile) }}
        </v-btn>
      </div>
      <div class="profile-card--body">
        <div class="profile-card--person-image">
          <v-avatar size="90" color="indigo">
            <v-img
              :src="changeThumbToSize(workspaceData.imageUrl, 'avatar') || defaultAvatarUrl(workspaceData.type === 'business' ? 'business' : 'profile')"
            />
          </v-avatar>
        </div>
        <div class="profile-card--person-info">
          <h5 class="profile-card--person-name">
            {{ workspaceData.name }} {{ workspaceData.lastName }}
          </h5>
          <h5 class="profile-card--person-title h7">
            {{ workspaceData.position }}
          </h5>
          <div class="profile-card--skills-container">
            <v-chip
            v-for="skill in mainSkills"
            :key="skill.id"
            class="profile-card--skill-chip"
            :class="setSkillColor(skill.level)"
            :dark="skill.level !== 'interest'"
            >
              {{ skill.title }}
            </v-chip>
          </div>
          <p class="profile-card--person-description">
            {{ workspaceData.resume || $t('card.public.profile:empty.resume')}}
          </p>
          <div class="profile-card--person-site-and-work" v-if="!isMobile">
            <div class="profile-card--person-site mr-14">
              <v-icon>mdi-map-marker</v-icon>{{ workspaceData.location || ' - ' }}
            </div>
            <div v-if="!isBusiness" class="profile-card--person-work">
              <v-icon>mdi-briefcase</v-icon> {{ workspaceData.company || ' - ' }}
            </div>
          </div>
        </div>
        <template v-if="!isMobile">
          <div class="profile-card--person-links">
            <div class="profile-card--person-link-list">
              <h5 class="profile-card--label h7">
                {{ $t('global:links') }}
              </h5>
              <p class="profile-card--person-links-description" v-if="!workspaceData.links || !workspaceData.links.length">{{ $t("card.public.profile:empty.links") }}</p>
              <div v-else class="profile-card--person-links-container">
                <v-btn
                  type="link"
                  :href="value"
                  target="_blank"
                  link
                  icon
                  :key="i"
                  outlined
                  class="mr-2 btn-link"
                  v-for="({ type, value }, i) in workspaceData.links"
                >
                  <v-icon fab>{{ socialIcons[type] }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <v-btn class="mobile-show-more--buton" text color="#1200D3" @click="showModalWorkspaceInfo = !showModalWorkspaceInfo"><b>{{ $t('global:show.more.details') }}</b></v-btn>
        </template>
      </div>
      <div class="profile-card--tabs-container">
        <v-tabs :show-arrows="isMobile" centered v-model="selectedTab" class="profile-card--tabs" slider-size="4">
          <v-tab class="btn" @click="$router.push({ name: selectedWorkspace.type + '.workspace.public.contents' })">{{ $t('global:contents') }}</v-tab>
          <v-tab class="btn" @click="$router.push({ name: selectedWorkspace.type + '.workspace.public.lists' })">{{ $tc('global:list', 2) }}</v-tab>
          <v-tab v-if="!isBusiness" class="btn" @click="$router.push({ name: selectedWorkspace.type + '.workspace.public.skills' })">{{ $t('global:skills') }}</v-tab>
        </v-tabs>
      </div>
    </section>
    <modal-workspace-info v-if="isMobile && showModalWorkspaceInfo" @close="showModalWorkspaceInfo = false"/>
  </v-card>
</template>

<style lang="scss">
.profile-card {
  padding: 20px 35px;
  .profile-card--label {
    text-align: left;
    color: $neutral-medium;
    align-items: center;
  }
  .profile-card--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .profile-card--body {
    display: flex;
    .profile-card--person-image {
      display: flex;
      .v-image__image {
        border-radius: 100px;
      }
      @media only screen and (max-width: 768px) {
        display: block;
        margin: 0 auto;
      }
    }
    .profile-card--person-info {
      margin: 0 25px;
      text-align: left;
      width: calc(70% - 100px);

      .profile-card--person-name {
        @extend .lato;
        color: $neutral-darkest;
        @media only screen and (max-width: 768px) {
          font-size: $font-size-medium;
          margin-top: 15px;
        }
      }

      .profile-card--person-title {
        @extend .lato;
        color: $neutral-medium;
      }

      .profile-card--person-description {
        @extend .lato;
        margin-top: 20px;
        color: $neutral-medium;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: $font-size-normal*1.2*4; /* Fallback for non-webkit */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile-card--skills-container {
        margin-top: 15px;
        .profile-card--skill-chip:not(:first-child) {
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
      }
    }
    .profile-card--tabs-container {
      .theme--light.v-tabs > .v-tabs-bar {
        background: $neutral-white;
        color: $primary-medium !important;
        caret-color: $primary-medium !important;
      }
    }
    .profile-card--person-site-and-work {
      display: flex;
      color: $neutral-medium;
      .profile-card--person-site {
        margin-right: 10px;
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        .profile-card--person-site {
          margin-bottom: 10px;
        }
      }
    }

    .profile-card--person-links {
      border-left: 1px solid $neutral-light;
      padding-left: 20px;

      .profile-card--person-links-description {
        @extend .lato;
        margin-top: 0px;
        color: $neutral-medium;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: $font-size-normal*1.2*4; /* Fallback for non-webkit */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .profile-card--person-links-container {
        text-align: left;
        width: 250px;
        .btn-link {
          margin-top: 8px;
        }
      }
      @media only screen and (max-width: 768px) {
        border-left: none;
        border-top: 1px solid $neutral-light;;
        margin-top: 20px;
        padding: 15px 0;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .profile-card--tabs-container {
    margin-bottom: -20px;
    .theme--light.v-tabs > .v-tabs-bar {
      background: $neutral-white;
      color: $primary-medium !important;
      caret-color: $primary-medium !important;
    }
    @media only screen and (max-width: 768px) {
      margin: 0 -20px -10px;
    }
  }
  .v-chip.primary-lightest {
    background: $primary-lightest;
  }
  .v-chip.primary-light {
    background: $primary-light;
  }
  .v-chip.primary-medium {
    background: $primary-medium;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 25px;
    .mobile-show-more--buton {
      font-family: $lato;
      margin-bottom: 30px;
    }
    .profile-card--label {
      margin-top: 8px;
    }
    .profile-card--person-description {
      position: relative;
      &:before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
      }
    }
    .profile-card--body {
      display: block;
      margin: 0 auto;
      .profile-card--person-info {
        text-align: center;
        .profile-card--person-name {
          font-size: $font-size-medium;
          margin-top: 15px;
        }
      }
    }
  }

  .empty-block {
    .empty-title {
      margin-top: -15px;
      color: $neutral-medium;
      font-weight: bold;
      font-size: 28px;
    }
    .empty-img {
      margin: 0 auto;
      margin-bottom: 30px;
      width: 136px;
      height: 120px;
    }
    .empty-text {
      font-family: $lato;
      font-size: $font-size-normal;
      color: $neutral-medium;
      max-width: 480px;
      margin: 0 auto;
    }
  }
}
</style>
