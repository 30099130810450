<script>
export default {
  name: 'ModalWorkspaceInfo',
  data () {
    return {
      mainSkills: null,
      socialIcons: {
        instagram: 'mdi-instagram',
        twitter: 'mdi-twitter',
        facebook: 'mdi-facebook',
        linkedin: 'mdi-linkedin',
        url: 'mdi-link'
      }
    }
  },
  computed: {
    workspaceId () {
      return this.$route.params.id
    },
    isBusiness () {
      return (this.workspaceData && this.workspaceData.type === 'business') || false
    },
    workspaceData () {
      return this.$store.getters.getWorkspaceInfos[this.workspaceId] || null
    }
  },
  methods: {
    updateWorkspace () {
      this.$store.dispatch('attemptGetWorkspaceById', this.workspaceId)
    }
  },
  created () {
    this.updateWorkspace()
  }
}
</script>

<template>
  <v-dialog :value="true" persistent width="800">
    <v-card  class="center-medium mobile-profile-card" :key="workspaceData.id">
      <section>
        <div class="mobile-profile-card--header">
        <span class="top-right">
          <v-btn icon @click="$emit('close')">
            <v-icon color="#454545">mdi-close</v-icon>
          </v-btn>
        </span>
        </div>
        <div class="mobile-profile-card--body">
          <div class="mobile-profile-card--person-image">
            <v-avatar size="90" color="indigo">
              <v-img
                :src="workspaceData.imageUrl || defaultAvatarUrl(workspaceData.type === 'business' ? 'business' : 'profile')"
              />
            </v-avatar>
          </div>
          <div class="mobile-profile-card--person-info">
            <h2 class="mobile-profile-card--person-name">
              {{ workspaceData.name }} {{ workspaceData.lastName }}
            </h2>
            <h5 class="mobile-profile-card--person-title">
              {{ workspaceData.position }}
            </h5>
            <div class="mobile-profile-card--skills-container">
              <v-chip
                v-for="skill in mainSkills"
                :key="skill.id"
                class="mobile-profile-card--skill-chip"
                :class="setSkillColor(skill.level)"
                :dark="skill.level !== 'interest'"
              >
                {{ skill.title }}
              </v-chip>
            </div>
            <p class="mobile-profile-card--person-description">
              {{ workspaceData.resume || $t('card.public.profile:empty.resume')}}
            </p>
            <div class="mobile-profile-card--person-site-and-work">
              <div class="mobile-profile-card--person-site">
                <v-icon>mdi-map-marker</v-icon>{{ workspaceData.location || ' - ' }}
              </div>
              <div v-if="!isBusiness" class="mobile-profile-card--person-work">
                <v-icon>mdi-briefcase</v-icon> {{ workspaceData.company || ' - ' }}
              </div>
            </div>
          </div>
          <div class="mobile-profile-card--person-links">
            <div class="mobile-profile-card--person-link-list">
              <h5 class="mobile-profile-card--label">
                {{ $t('global:links') }}
              </h5>
              <p class="mobile-profile-card--person-links-description" v-if="!workspaceData.links || !workspaceData.links.length">{{ $t("card.public.profile:empty.links") }}</p>
              <div v-else class="mobile-profile-card--person-links-container">
                <v-btn
                  type="link"
                  :href="value"
                  target="_blank"
                  link
                  icon
                  :key="i"
                  outlined
                  class="mr-2 btn-link"
                  v-for="({ type, value }, i) in workspaceData.links"
                >
                  <v-icon fab>{{ socialIcons[type] }}</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </section>
    </v-card>
  </v-dialog>
</template>

<style lang="scss">
.mobile-profile-card {
  padding: 20px 35px;
  .mobile-profile-card--label {
    text-align: left;
    color: $neutral-medium;
    align-items: center;
    padding-bottom: 10px;
  }
  .mobile-profile-card--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .mobile-profile-card--body {
    display: flex;
    .mobile-profile-card--person-image {
      display: flex;
      .v-image__image {
        border-radius: 100px;
      }
      @media only screen and (max-width: 768px) {
        display: block;
        margin: 0 auto;
      }
    }
    .mobile-profile-card--person-info {
      margin: 0 25px;
      text-align: left;
      width: calc(70% - 100px);

      .mobile-profile-card--person-name {
        @extend .lato;
        font-size: $font-size-large;
        color: $neutral-darkest;
        @media only screen and (max-width: 768px) {
          font-size: $font-size-medium;
          margin-top: 15px;
        }
      }

      .mobile-profile-card--person-title {
        @extend .lato;
        font-size: $font-size-normal;
        color: $neutral-medium;
      }

      .mobile-profile-card--person-description {
        @extend .lato;
        margin-top: 20px;
        color: $neutral-medium;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: $font-size-normal*1.2*8; /* Fallback for non-webkit */
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mobile-profile-card--skills-container {
        margin-top: 15px;
        .mobile-profile-card--skill-chip:not(:first-child) {
          margin-left: 10px;
        }
      }
      @media only screen and (max-width: 768px) {
        margin: 0;
        width: 100%;
      }
    }

    .mobile-profile-card--person-site-and-work {
      display: flex;
      color: $neutral-medium;
      .mobile-profile-card--person-site {
        margin-right: 10px;
      }
      @media only screen and (max-width: 768px) {
        flex-direction: column;
        .mobile-profile-card--person-site {
          margin-bottom: 10px;
        }
      }
    }

    .mobile-profile-card--person-links {
      border-left: 1px solid $neutral-light;
      padding-left: 20px;

      .mobile-profile-card--person-links-description {
        @extend .lato;
        margin-top: 0px;
        color: $neutral-medium;
        display: block; /* Fallback for non-webkit */
        display: -webkit-box;
        height: $font-size-normal*1.2*4; /* Fallback for non-webkit */
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .mobile-profile-card--person-links-container {
        text-align: left;
        width: 250px;
        .btn-link {
          margin-top: 8px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  .mobile-profile-card--tabs-container {
    margin-bottom: -20px;
    .theme--light.v-tabs > .v-tabs-bar {
      background: $neutral-white;
      color: $primary-medium !important;
      caret-color: $primary-medium !important;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: -10px;
    }
  }
  .v-chip.primary-lightest {
    background: $primary-lightest;
  }
  .v-chip.primary-light {
    background: $primary-light;
  }
  .v-chip.primary-medium {
    background: $primary-medium;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 25px;
    .mobile-profile-card--body {
      display: block;
      margin: 0 auto;
      .mobile-profile-card--person-info {
        text-align: center;
        .mobile-profile-card--person-name {
          font-size: $font-size-medium;
          margin-top: 15px;
        }
      }
      .mobile-profile-card--person-links {
        padding: 0;
        border-left: none;
        margin-top: 45px;
      }
      .mobile-profile-card--label {
        text-align: center;
      }
    }
  }
}
</style>
